import CognitoUtil from '../../../components/aws/cognito/cognitoUtil'

const JWT_EXPIRATION_DURATION = 300; // 5 minutes
const LOCAL_STORAGE_KEYS = {
    RC_API_JWT: 'RC_API_JWT',
    RC_API_JWT_EXPIRATION: 'RC_API'
}

export default async function apiRequest(stage, endpoint = '', authToken, method, data = {}) {
    // Default options are marked with *
    const requestObject = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer' // no-referrer, *client
    };

    if (method !== 'GET') {
        requestObject.body = JSON.stringify(data); // body data type must match "Content-Type" header
        requestObject.headers['Content-Type'] = 'application/json';
    }

    const url = getApiUrl(endpoint, stage);

    const response = await fetch(url, requestObject);

    const contentType = response.headers.get("content-type");
    const location = response.headers.get("location"); // for 201 Created responses
    
    let body;
    
    if (contentType && contentType.indexOf("application/json") !== -1) {
        body = await response.json();
        body.location = location;
    } else {
        body = undefined;
    }


    if (response.status > 300) {
        let errorMessage = body && body.error;
        if (!errorMessage) errorMessage = "";
        const error = new Error(errorMessage);
        error.code = response.status;
        return Promise.reject(error);
    }

    return Promise.resolve(body);
}

export function getApiUrl(endpoint, stage) {
    const urlTemplate = process.env.REACT_APP_RC_API_BASE_URL + endpoint;
    
    const dotstage = "prod" == stage ? "" : `.${stage}`

    return urlTemplate.replace('{.stage}', dotstage)
}

const  getAPIJWT = (userPoolIdTokenJWT, stage) => {
    
    if (!userPoolIdTokenJWT) return Promise.reject(new Error('userPoolIdTokenJWT is required'));

    const method = 'POST';

    return apiRequest(stage, '/auth/token', userPoolIdTokenJWT, method)
    .then(
        (response) => {
            //{"token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIU...TRUNCATED"}
            if (!response.token) {
                if (response.message && response.message.includes("NotAuthorizedException")) {
                    const error = new Error('NotAuthorizedException');
                    error.code = 401;
                    return Promise.reject(error);
                }
                return Promise.reject(new Error('Unknown error occurred'));
            }
            return Promise.resolve(response.token);
        },
        (error) => {
            return Promise.reject(error); // passed to .catch below
        }
    )
    .catch((error) => { // catches other exceptions and promise rejects above
        return Promise.reject(error);
    });
}

const refreshTempCredentials = (stage, lastTry = false) => {

    const userPoolIdTokenJWT = CognitoUtil.getCurrentUserToken();

    return getAPIJWT(userPoolIdTokenJWT, stage)
    .then(
        (apiJWT) => {
            setLocalAPIJWT(apiJWT);
            return Promise.resolve(apiJWT);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
    .catch( (error) => {
        if (error.code) {
            if(error.code === 401 && !lastTry) {
                console.log("Unauthorized due to expired temporary user session, trying to refresh login");
                // Unauthorized due to expired temporary user session, try to refresh
                return new Promise( (resolve, reject) => {
                    console.log("refreshing login...");
                    CognitoUtil.refreshLogin();
                    console.log("now trying again to refresh credentials...");
                    // If the issue was due to expired temporary user session, it should be fixed now. 
                    // If not, the issue is something else (probably on the server) and we should reject the promise
                    // without the 'lastTry' flag, this would cause an infinite loop
                    refreshTempCredentials(stage, true)
                    .then(
                        (newApiJWT) => {
                            resolve(newApiJWT);
                            return;
                        },
                        (error) => {return Promise.reject(error);}
                    )
                    .catch( (error) => {
                        reject(error);
                        return;
                    });
                });
            }
        }
        return Promise.reject(error);
    });
}

export const checkAPIJWT = async (stage) => {
    
    const apiJWT = getLocalAPIJWT();

    if (apiJWT) return Promise.resolve(apiJWT);

    return refreshTempCredentials(stage);     
};

const getLocalAPIJWT = () => {
    const expiration = localStorage.getItem(LOCAL_STORAGE_KEYS.RC_API_JWT_EXPIRATION);

    if (!expiration) return null;

    const currentLocalUnixTime = Math.floor(Date.now() / 1000);
    if (expiration < currentLocalUnixTime) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.RC_API_JWT);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.RC_API_JWT_EXPIRATION);
        return null;
    };

    return localStorage.getItem(LOCAL_STORAGE_KEYS.RC_API_JWT);
}

const setLocalAPIJWT = (apiJWT) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.RC_API_JWT, apiJWT);

    const currentLocalUnixTime = Math.floor(Date.now() / 1000);
    const expiration = currentLocalUnixTime + JWT_EXPIRATION_DURATION;

    localStorage.setItem(LOCAL_STORAGE_KEYS.RC_API_JWT_EXPIRATION, expiration);
}


export const clearLocalAPIJWT = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.RC_API_JWT);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.RC_API_JWT_EXPIRATION);
}