import React from 'react';
import PropTypes from 'prop-types';
import Header from './panels/Header';
import { ModalContainer, ConfirmationDialog } from '../../modals/Modal2';
import { MAX_LENGTH, MAIN_FILTER_TYPES, PLACEHODERS, GET_BEACONS_TIMEOUT, HIDE_MESSAGES_TIMEOUT } from './constants';
import Beacons from '../../../data/models/beacon';
import Accounts from '../../../data/models/account';
import ProgressOverlay from '../../common/ProgressOverlay';
import VALIDATION_RULES from './validation';
import SearchBox from '../../common/searchBox';

export default class BeaconsPage extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            stage: this.props.match.params.stage,
            beaconFormModal: null,
            beacons: [],
            accounts: [],
            currentAccount: this.getCurrentAccountFromUrl(),
            actionInProgress: false,
            mainFilter: MAIN_FILTER_TYPES.SHOW_ALL,
            showConfirmDeleteDialog: false,
            beaconToDelete: null,
            actionError: null,
            actionMessage: null,
            keyword: '',
            collapsed: true
        };

        this.getBeaconsTimeoutId = undefined; // actionInProgress set resets correspond to this
        this.hideMessageTimeoutId = undefined;
        this.beaconsTableWrapperRef = React.createRef();
        this.stickyTopBarRef = React.createRef();
        this.stickyTopBarThresholdRef = React.createRef();

    }

    generateErrorMessage = (error, action) => {

        if(process.env.NODE_ENV === 'development'){
            const {code, message} = error;
            console.error("Beacons Page Error", action, code, message);
        }

        let actionMessage = "";
        let errorType = "An error";

        switch (action) {
            case "add":
                actionMessage = "add the new beacon";
                break;
            case "delete":
                actionMessage = "delete the beacon";
                break;
            case "get":
                actionMessage = "load the beacons";
                break;
            case "account":
                actionMessage = "get the account";
                break;
        }

        if(error.code && error.code >= 500){
            errorType = "A server error";
        }

        return `${errorType} occurred${actionMessage ? ` while trying to ${actionMessage}` : ""}.`;
    }

    getCurrentAccountFromUrl = () => {
        const accountExternalId = this.props.match.params.id;
        const accountEmail = this.props.match.params.email;

        if (accountExternalId && accountEmail) {
            return {
                accountExternalId,
                email: accountEmail
            };
        }

        return null;
    }

    timeoutGetBeacons = () => {
        this.setState({
            actionError: "It is taking too long to load the beacons.",
            actionInProgress: false
        });
    }

    hideMessages = () => {
        this.setState({
            actionMessage: null
        });
    }

    searchBeacons = (keyword) => {
        if(!keyword) return;
        this.setState({keyword});
    }

    clearSearchBeacons = () => {
        this.setState({keyword: ''});
    }

    getBeacons = (limit = null, offset = 0) => {

        if (this.getBeaconsTimeoutId !== undefined) return;

        this.getBeaconsTimeoutId = setTimeout(() => {this.timeoutGetBeacons()}, GET_BEACONS_TIMEOUT);

        this.setState({actionInProgress: true});

        let beaconsFilters = {};
        
        if (limit) {
            beaconsFilters.limit = limit;
            beaconsFilters.skip = offset; // is it called "skip" b/c MongoDB?
        }

        Promise.all([
           Beacons.selectors.getBeacons(this.state.stage, this.state.currentAccount, this.state.keyword, beaconsFilters.limit, beaconsFilters.skip),
        ])
        .then(
            ([beacons]) => {
                const currentBeacons = this.state.beacons ? this.state.beacons : [];
                this.setState({
                    beacons: currentBeacons.concat(beacons),
                    actionError: null
                });
            },
            (error) => {
                if(error.code === 404){
                    this.setState({
                        beacons: [],
                        actionError: null
                    });
                } else {
                    this.setState({
                        actionError: this.generateErrorMessage(error, "get")
                    });
                }
            }
        )
        .then(
            () => {
                clearTimeout(this.getBeaconsTimeoutId);
                this.getBeaconsTimeoutId = undefined;
                this.setState({actionInProgress: false});
            }
        )
    }

    getAccounts = (keyword) => {
        if(!keyword) return;

        this.setState({
            searchingAccountsInProgress: true
        });

        Accounts.selectors.getAccounts(this.state.stage, keyword)
        .then(
            (list) => {
                this.setState({
                    accounts: list, 
                    searchingAccountsInProgress: false
                });
            },
            (error) => {
                this.setState({
                    actionError: this.generateErrorMessage(error, "account"),
                    searchingAccountsInProgress: false
                });
            }
        );
    }

    clearAccounts = () => {
        this.setState({accounts: []});
    }

    componentDidMount() {
        if(this.state.stage && this.state.currentAccount){
            this.getBeacons();
        }

        window.addEventListener('scroll', this.enableStickyTopBar, true);
    }

    componentDidUpdate(prevProps, prevState) {
        
        const stageUrlParam = this.props.match.params.stage;
        
        if (stageUrlParam !== this.state.stage) {
            this.setState({
                stage: stageUrlParam,
                beacons: [],
                accounts: [],
                currentAccount: null,
                mainFilter: MAIN_FILTER_TYPES.SHOW_ALL,
                actionInProgress: false,
                searchingAccountsInProgress: false,
                beaconFormModal: null,
                showConfirmDeleteDialog: false,
                beaconToDelete: null,
                actionError: null,
                actionMessage: null,
                keyword: '',
                collapsed: true
            });
        }

        if(this.state.currentAccount && this.state.currentAccount !== prevState.currentAccount){
            this.setState({
                beacons: [],
                mainFilter: MAIN_FILTER_TYPES.SHOW_ALL,
                actionInProgress: false,
                searchingAccountsInProgress: false,
                actionError: null,
                actionMessage: null,
                keyword: '',
                collapsed: true
            });

            this.getBeacons();
        }
    }

    onRavenStageChanged = (stage) => {
        this.setState({
            stage: stage,
            beacons: [],
            accounts: [],
            currentAccount: null,
            mainFilter: MAIN_FILTER_TYPES.SHOW_ALL,
            actionInProgress: false,
            searchingAccountsInProgress: false,
            beaconFormModal: null,
            showConfirmDeleteDialog: false,
            beaconToDelete: null,
            actionError: null,
            actionMessage: null,
            keyword: '',
            collapsed: true
        });

        Beacons.actions.resetToken(stage);

        this.props.history.push("/beacons/" + stage);
    }

    onMainFilterClick = (filter) => {
        if (!MAIN_FILTER_TYPES.hasOwnProperty(filter)) {
            console.log("BeaconsPage onMainFilterClick invalid main filter name: " + filter);
            return;
        }

        this.setState({mainFilter: filter});
    }

    includesKeyword = (beacon) => {
        const keyword = this.state.keyword.toLowerCase();

        return beacon.btMac.toLowerCase().includes(keyword) ||
            (beacon.make && beacon.make.toLowerCase().includes(keyword)) ||
            (beacon.model && beacon.model.toLowerCase().includes(keyword)) ||
            (beacon.assetName && beacon.assetName.toLowerCase().includes(keyword));
    }

    getBeaconsForCurrentFilter = () => {
        return this.state.beacons.filter(beacon => {
            const matchesKeyword = !this.state.keyword || this.includesKeyword(beacon); 
            let matchesFilter = true;

            switch(this.state.mainFilter) {
                case MAIN_FILTER_TYPES.SHOW_ONLY_ASSIGNED:
                    matchesFilter = beacon.assetUuid;
                    break;
                case MAIN_FILTER_TYPES.SHOW_ONLY_UNASSIGNED:
                    matchesFilter = !beacon.assetUuid;
                    break;
            }

            return matchesKeyword && matchesFilter;
        });
    }

    onBeaconsTableScroll = (e) => {

    }

    onAddBeacon = () => {
        const closeModal = () => {
            this.setState({beaconFormModal: null});    
        };

        this.setState({
            beaconFormModal: <BeaconForm
                account={this.state.currentAccount}
                stage={this.state.stage}
                onCancel={closeModal}
                onSuccess={(newBeacon) => {
                    return this.addBeacon(newBeacon);
                }}
            />
        });
    }

    addBeacon(beacon) {
        this.setState({actionInProgress: true});
        
        return Beacons.actions.addBeacon(this.state.stage, this.state.currentAccount, beacon)
        .then(
            (newBeacon) => {
                const currentBeacons = this.state.beacons ? this.state.beacons : [];

                this.setState({
                    beacons: currentBeacons.concat(newBeacon),
                    actionError: null,
                    actionMessage: "Beacon added successfully",
                    beaconFormModal: null
                });

                this.hideMessageTimeoutId = setTimeout(() => {this.hideMessages()}, HIDE_MESSAGES_TIMEOUT);
            },
            (error) => {
                const errorMessage = this.generateErrorMessage(error, "add");
                this.setState({
                    actionError: errorMessage
                });

                return Promise.reject(errorMessage); // to be caught by the caller. This is important for the form to know that the action failed and to show an error message and keep data in the form.
            }
        ).finally(() => {
            this.setState({actionInProgress: false});
        });
    }

    onEditBeacon = (beaconUuid) => {
        const closeModal = () => {
            this.setState({beaconFormModal: null});    
        };

        this.setState({
            beaconFormModal: <BeaconForm
                stage={this.state.stage}
                onCancel={closeModal}
                beacon={this.state.beacons.find(beacon => beacon.beaconUuid === beaconUuid)}
                onSuccess={() => {
                    this.setState({
                        orders: null,
                        actionInProgress: false
                    });            
                    this.getBeacons(this.state.stage, null, 0, MAIN_FILTER_TYPES.SHOW_ALL); // basic getBeacons(stage) with refreshFilterOverride
                    closeModal();
                }}
            />
        });
    }
    
    onDeleteBeacon = (beacon) => {
        this.setState({ beaconToDelete: beacon });
        this.showConfirmDeleteDialog();
    }

    showConfirmDeleteDialog = () => {
        this.setState({ showConfirmDeleteDialog: true });
    }

    closeConfirmDeleteDialog = () => {
        this.setState({ beaconToDelete: null });
        this.setState({ showConfirmDeleteDialog: false });
    }

    deleteBeacon = () => {
        this.closeConfirmDeleteDialog();

        this.setState({actionInProgress: true});

        Beacons.actions.deleteBeacon(this.state.stage, this.state.currentAccount, this.state.beaconToDelete)
        .then(
            (deletedBeacon) => {
                const currentBeacons = this.state.beacons ? this.state.beacons : [];
                const updatedBeacons = currentBeacons.filter(beacon => beacon.beaconUuid !== deletedBeacon.beaconUuid);
                
                this.setState({
                    beacons: updatedBeacons,
                    actionError: null,
                    actionMessage: `Beacon ${deletedBeacon.btMac} deleted successfully`,
                    beaconToDelete: null
                });

                this.hideMessageTimeoutId = setTimeout(() => {this.hideMessages()}, HIDE_MESSAGES_TIMEOUT);
            },
            (error) => {
                this.setState({
                    actionError: this.generateErrorMessage(error, "delete")
                })
            }
        ).finally(() => {
            this.setState({actionInProgress: false});
        });
    }

    onAccountChanged = (account) => {
        this.setState({currentAccount: account, accounts: []});
        this.props.history.push("/beacons/" + this.state.stage + "/" + account.accountExternalId  + "/" + account.email);
    }
    
    toggleCards = () => {
        this.setState({collapsed: !this.state.collapsed});
    }

    noBeaconsMessage = () => {
        let noBeaconsTitle = "No beacons";

        switch(this.state.mainFilter) {
            case MAIN_FILTER_TYPES.SHOW_ONLY_ASSIGNED:
                noBeaconsTitle = "No assigned beacons";
                break;
            case MAIN_FILTER_TYPES.SHOW_ONLY_UNASSIGNED:
                noBeaconsTitle = "No unassigned beacons";
                break;
        }

        return this.state.keyword ? 
        `${noBeaconsTitle} found for the keyword '${this.state.keyword}'`:
        `${noBeaconsTitle} found!`
    }

    enableStickyTopBar = (e) => {

        if(this.stickyTopBarRef.current === null || this.stickyTopBarThresholdRef.current === null) return;
        if(e.target.id !== "beacons-page-overflow-scrolling-div-id") return;

        const stickyTopBar = this.stickyTopBarRef.current;
        const stickyTopBarThreshold = this.stickyTopBarThresholdRef.current;

        const currentScroll = e.target.scrollTop;
        
        if (currentScroll > stickyTopBarThreshold.offsetTop) {
            stickyTopBar.classList.add('sticky');           
        } else {
            stickyTopBar.classList.remove('sticky');
        }
    }

    render () {
        const beaconsForCurrentFilter = this.getBeaconsForCurrentFilter();
        const showSearchBox = this.state.beacons.length > 0;
        const showTools = beaconsForCurrentFilter.length > 0;
        const showBeaconsCards = beaconsForCurrentFilter.length > 0;
        const showNoBeaconsMessage = beaconsForCurrentFilter.length === 0 && !this.state.actionInProgress && !this.state.actionError;

        return (

            <div className="feature-page beacons-page" id="beacons-page-overflow-scrolling-div-id">
                <Header
                    ravenStages={ this.props.stages }
                    stage={this.props.match.params.stage}
                    onRavenStageChange={this.onRavenStageChanged}
                />

                <section className="main-content" ref={this.beaconsTableWrapperRef} >
                    <h1>
                        Beacons
                    </h1>
                    {this.state.currentAccount ?
                        <>
                            <div className="options" ref={this.stickyTopBarRef}>
                                <BeaconsPageMainFilters beacons={this.state.beacons} mainFilter={this.state.mainFilter} onMainFilterClick={this.onMainFilterClick} />
                                <div>
                                    <div className="search-accounts"><h3>Account</h3><SearchBox keyword={this.state.currentAccount.email} onChange={this.getAccounts} onSelect={this.onAccountChanged} results={this.state.accounts} textProp={"email"} inProgress={this.state.searchingAccountsInProgress}  /></div>
                                    <div className="search-beacons"><SearchBox onSearch={this.searchBeacons} onReset={this.clearSearchBeacons} keyword={this.state.keyword}  /></div>
                                </div>
                                <div>
                                    <button className='toggle-all-cards' onClick={this.toggleCards}>{this.state.collapsed ? <><i className="material-icons">keyboard_arrow_down</i>Expand All</> : <><i className="material-icons">keyboard_arrow_up</i>Collapse All</>}</button>
                                    <button className="add add-beacon" onClick={this.onAddBeacon}>Add New Beacon</button>
                                </div>
                            </div>
                            <div className='container'>

                                <div className="row">
                                    <div className='col-8 offset-2 messages'>
                                        <ul className={this.state.actionError || this.state.actionMessage ? "d-block" : "d-none"}>
                                            {this.state.actionError &&
                                                (<li className="error-message">{this.state.actionError}</li>)}
                                            {this.state.actionMessage &&
                                                (<li className="message">{this.state.actionMessage}</li>)}
                                        </ul>
                                    </div>
                                </div>

                                {showSearchBox &&
                                    <div className='row search-beacons'>
                                        <div className='col-8 offset-2'>
                                            <SearchBox 
                                                onSearch={this.searchBeacons} 
                                                onReset={this.clearSearchBeacons}
                                                keyword={this.state.keyword}
                                            />
                                        </div>
                                    </div>                                
                                }

                                {showTools &&
                                    <div className='row tools'>
                                        <div className='col-8 offset-2 d-flex justify-content-between mb-2'>
                                            <div>{this.state.keyword && <span>{beaconsForCurrentFilter.length} Beacons Found.</span>}</div>
                                            <button className='toggle-all-cards' onClick={this.toggleCards}>{this.state.collapsed ? <><i className="material-icons">keyboard_arrow_down</i>Expand All</> : <><i className="material-icons">keyboard_arrow_up</i>Collapse All</>}</button>
                                        </div>
                                    </div>
                                }

                                {showBeaconsCards && 
                                    <div className='row beacons' ref={this.stickyTopBarThresholdRef}>
                                            <BeaconsCards 
                                                collapsed={this.state.collapsed} 
                                                beacons={beaconsForCurrentFilter} 
                                                onDeleteBacon={this.onDeleteBeacon} 
                                                onEditBacon={this.onEditBeacon}
                                                filter={this.state.mainFilter}
                                                keyword={this.state.keyword}
                                            />
                                    </div>
                                }

                                {showNoBeaconsMessage &&
                                    <div className='row no-beacons'>
                                        <div className='col-8 offset-2 mt-5 text-center fw-bolder'>
                                            {this.noBeaconsMessage()}
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                        :
                        <div className="select-account col-10 offset-1 col-xl-6 offset-xl-3">
                            <h3 className='text-center'>Select an account to start managing beacons</h3>
                            <div className='messages'>
                                <ul className={this.state.actionError || this.state.actionMessage ? "d-block" : "d-none"}>
                                    {this.state.actionError &&
                                        (<li className="error-message">{this.state.actionError}</li>)}
                                    {this.state.actionMessage &&
                                        (<li className="message">{this.state.actionMessage}</li>)}
                                </ul>
                            </div>
                            <SearchBox 
                                onChange={this.getAccounts} 
                                onSelect={this.onAccountChanged}
                                onReset={this.clearAccounts} 
                                results={this.state.accounts} 
                                textProp={"email"}
                                placeholder="Enter the EMAIL ADDRESS or EXTERNAL ID"
                                inProgress={this.state.searchingAccountsInProgress} />
                        </div>
                    }
                    {this.state.actionInProgress && <ProgressOverlay />}
                    
                    {this.state.showConfirmDeleteDialog && <ConfirmationDialog
                        title="Delete Beacon?"
                        cancelButtonAction={this.closeConfirmDeleteDialog}
                        message={`<p>You're deleting the beacon <i>'${this.state.beaconToDelete.btMac}'</i> from account <i>'${this.state.currentAccount.email}'</i>.</p> ${this.state.beaconToDelete.assetName ? `<p class='text-warning'>Warning: It's assigned to the asset <i>'${this.state.beaconToDelete.assetName}'</i>.</p>` : ""}Click on 'Delete' to proceed.`}
                        cancelButtonTitle="Cancel"
                        submitButtonAction={this.deleteBeacon}
                        submitButtonTitle="Delete"
                        rawHtml={true}
                    />}
                    
                </section>
                {this.state.beaconFormModal}
            </div>
        );
    }
}

class BeaconsPageMainFilters extends React.PureComponent {

    static propTypes = {
        mainFilter: PropTypes.string.isRequired,
        onMainFilterClick:  PropTypes.func.isRequired
    };

    render() {
        return (
            <div className="main-filters">

                {Object.keys(MAIN_FILTER_TYPES).map((MAIN_FILTER_TYPE) => {

                    let buttonLabel = "Unknown";
                    let count = 0;
                    switch (MAIN_FILTER_TYPE) { 
                        case MAIN_FILTER_TYPES.SHOW_ALL: 
                            buttonLabel = "All";
                            count = this.props.beacons.length;
                            break;
                        case  MAIN_FILTER_TYPES.SHOW_ONLY_ASSIGNED: 
                            buttonLabel = "Assigned"; 
                            count = this.props.beacons.filter(beacon => beacon.assetUuid).length;
                            break;
                        case  MAIN_FILTER_TYPES.SHOW_ONLY_UNASSIGNED: 
                            buttonLabel = "Unassigned";
                            count = this.props.beacons.filter(beacon => !beacon.assetUuid).length;
                            break;
                    }

                    return (
                        <button name={MAIN_FILTER_TYPE} disabled={count === 0} className={MAIN_FILTER_TYPE === this.props.mainFilter ? "active" : null} key={MAIN_FILTER_TYPE} onClick={(event) => {this.props.onMainFilterClick(event.target.name)}}>
                            {buttonLabel} ({count})
                        </button>
                    )
                })}

            </div>
        )
    }
}

class BeaconForm extends React.PureComponent {

    state = {
        beacon: {
            btMac: "",
            make: "",
            model: ""
        },
        errorMessages: {},
        pending: false
    }
    
    static propTypes = {
        stage: PropTypes.string.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired
    }

    validate = () => {
        const errors = {};

        const beaconFields = Object.keys(this.state.beacon);

        beaconFields.forEach(field => {
            if(errors[field]) return; // if already an error on that field, skip. This is to avoid overwriting the first error message with the second one.

            VALIDATION_RULES[field].forEach(rule => {
                if(!rule.isValid(this.state.beacon[field])){
                    errors[field] = rule.message;
                }
            });
        });

        this.setState({
            errorMessages: {...errors}
        });

        return Object.values(errors).length === 0;
    }

    onChange = (e) => {
        this.setState({
            beacon:
            {
                ...this.state.beacon,
                [e.target.id]: e.target.value
            }
        });
    }

    onKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.onSave();
        }
        if(e.keyCode === 27) {
            this.onCancel();
        }
    }

    onCancel = () => {
        this.reset();

        this.props.onCancel();
    }

    onSave = () => {
        const isValid = this.validate();

        if (!isValid) {
            return;
        }

        this.setState({pending: true});

        this.props.onSuccess(this.state.beacon)
        .then(() => {
            // nothing, the modal will be closed by the parent
        }).catch((error) => {
            this.setState({
                errorMessages: {general: error},
                pending: false
            });
        });
    }

    reset = () => {
        this.setState({
            beacon: {
                btMac: '',
                make: '',
                model: ''
            },
            errorMessages: {}
        });
    }

    renderErrorMessages = (field) => {
        if (this.state.errorMessages[field]) {
            return (
                <ul>
                    <li className='error-message'>{this.state.errorMessages[field]}</li>
                </ul>
            );
        }

        return null;
    }

    render () {
        const title = "Add New Beacon";

        return (
            <ModalContainer title={title} onClickClose={this.props.onCancel} >
                <div className='container-fluid form-card'>
                    <div className="row form pt-5">
                        <div className="required-legend">
                            <div>
                                <span className='required-badge'>•</span> required
                            </div>
                        </div>
                        <div className="col-12 content-group" onKeyUp={this.onKeyUp}>
                            <div className="row">
                                {this.renderErrorMessages('general')}
                            </div>
                            <div className="row mb-4">
                                <div className="col-2"><label htmlFor="btMac">Account </label></div>
                                <div className="col-10">{this.props.account.email}</div>
                            </div>                          
                            <div className="row">
                                <div className="col-2"><label htmlFor="btMac">MAC Address <span className='required-badge'>•</span></label></div>
                                <div className="col-10"><input autoComplete='off' type="text" id="btMac" value={this.state.beacon.btMac} maxLength={MAX_LENGTH.MAC_ADRESS} onChange={this.onChange} placeholder={PLACEHODERS.MAC_ADRESS} autoFocus /></div>
                                {this.renderErrorMessages('btMac')}
                            </div>
                            <div className="row">
                                <div className="col-2"><label htmlFor="make">Make</label></div>
                                <div className="col-10"><input autoComplete='off' type="text" id="make" value={this.state.beacon.make} maxLength={MAX_LENGTH.MAKE}  onChange={this.onChange}  placeholder={PLACEHODERS.MAKE} /></div>
                                {this.renderErrorMessages('make')}
                            </div>
                            <div className="row">
                                <div className="col-2"><label htmlFor="model">Model</label></div>
                                <div className="col-10"><input autoComplete='off' type="text" id="model" value={this.state.beacon.model} maxLength={MAX_LENGTH.MODEL}  onChange={this.onChange}  placeholder={PLACEHODERS.MODEL} /></div>
                                {this.renderErrorMessages('model')}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 actions">
                            <button className="destructive" onClick={this.onCancel}>Cancel</button>
                            <button onClick={this.onSave}  >Save</button>
                        </div>
                    </div>
                </div>
                {this.state.pending && <ProgressOverlay />}
            </ModalContainer>
        )
    }
}

class BeaconsCards extends React.PureComponent{

    onDelete = (beacon) => {
        this.props.onDeleteBacon(beacon);
    }

    onEdit = (beaconUuid) => {
        this.props.onEditBacon(beaconUuid);
    }

    render() {
        return (
                this.props.beacons.length > 0 ? 
                <>
                {this.props.beacons.map((beacon, i) =>
                        <BeaconCard
                            key={i}
                            beacon={beacon}
                            onDelete={this.onDelete}
                            onEdit={this.onEdit}
                            collapsed={this.props.collapsed}
                        />)}
                </>
                                 
                : null
        );
    }
}

class BeaconCard extends React.PureComponent {

    static propTypes = {
        beacon: PropTypes.object.isRequired,
        onDelete: PropTypes.func.isRequired,
        collapsed: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            collapsed: this.props.collapsed
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.collapsed !== this.props.collapsed) {
            this.setState({collapsed: this.props.collapsed});
        }
    }

    onDelete = () => {
        this.props.onDelete(this.props.beacon);
    }

    onEdit = () => {
        this.props.onEdit(this.props.beacon.beaconUuid);
    }

    getTitle = () => {
        return this.props.beacon.assetName ? this.props.beacon.assetName : this.props.beacon.btMac;
    }

    renderInfo = () => {
        const make = this.props.beacon.make ? this.props.beacon.make : "Unknown Make";
        const model = this.props.beacon.model ? this.props.beacon.model : make === "Unknown Make" ? "Model" : "Unknown Model";
        return (
            <>
                <div title="MAC Address" className="beacon-btmac card-detail"><i className="material-icons-outlined">info</i>{this.props.beacon.btMac.toUpperCase()}</div>
                <div title='Make and Model' className="beacon-make card-detail"><i className="material-icons-outlined">info</i>{make} / {model}</div>
            </>
        )
    }

    renderAssignedAsset = () => {
        return this.props.beacon.assetName ? 
        <div title='Assigned to' className="beacon-asset card-detail"><i className='material-icons'>link</i>{this.props.beacon.assetName} </div>:
        <div className="beacon-asset card-detail unassigned"><i className='material-icons'>link_off</i>Unassigned</div>;
    }

    renderLastSeen = () => {
        const {lastSeen, latitude, longitude} = this.props.beacon;
        return lastSeen && longitude && latitude ? 
        <>
            <div title='Last Seen' className="beacon-ts card-detail"><i className='material-icons-outlined'>visibility</i> {lastSeen}</div> 
            <div className='d-flex align-items-start flex-wrap'>
                {this.renderReportedBy()}
                <div title='Location' className="beacon-location card-detail"><i className='material-icons-outlined'>location_on</i><a href={`https://maps.google.com/?q=${latitude},${longitude}`} target='_blank'>{longitude} - {latitude}<i className='material-icons-outlined'>open_in_new</i></a></div> 
            </div>
        </>
        : <div className="beacon-ts card-detail"><i className="material-icons-outlined">visibility_off</i>Not Seen</div>;
    }

    renderReportedBy = () => {
        const {carPersona, enclosureSerialNo} = this.props.beacon;
    
        return carPersona && enclosureSerialNo ? 
        <>
            <div title="Reported By" className="beacon-reported-by card-detail">
                <i className='material-icons-outlined'>directions_car</i>
                <a href={`/raven/${this.props.stage}/${enclosureSerialNo}`} target='_blank'>
                    {carPersona} [{enclosureSerialNo}]<i className='material-icons-outlined'>open_in_new</i>
                </a>
            </div> 
        </>
        : null;
    }

    renderBattery = () => {
        const {batteryLife} = this.props.beacon;
        return batteryLife ? <BeaconBattery value={batteryLife.value} time={batteryLife.time} /> : null;
    }

    toggleCard = () => {
        this.setState({collapsed: !this.state.collapsed});
    }

    render() {
        return (
            <div className={`col-8 offset-2 beacon-card ${this.state.collapsed ? 'collapsed' : ''}`}>
                <div className="row">
                    {this.state.collapsed ?
                    <>
                        <div className='col-5 d-flex align-items-center' onClick={this.toggleCard}><h3 className='mac-address card-title'><i className="material-icons white">keyboard_arrow_down</i>{this.getTitle()}</h3></div>
                        <div className='col-4 d-flex align-items-center justify-content-start'>{this.renderAssignedAsset()}</div>
                        <div className='col-3 text-right actions'>
                            <button className='delete-beacon' onClick={this.onDelete}>Delete</button>
                        </div>
                    </>
                    :
                    <>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-9'>
                                    <h3 onClick={this.toggleCard} className="mac-address card-title"><i className="material-icons white">keyboard_arrow_up</i>{this.getTitle()}</h3>
                                </div>
                                <div className='col-3 text-right actions'>
                                    <button className='delete-beacon' onClick={this.onDelete}>Delete</button>
                                </div>                                
                            </div>
                            <div className='row'>
                                <div className='card-details col-12 col-lg-4'>
                                    {this.renderAssignedAsset()}
                                    {this.renderInfo()}
                                </div>
                                <div className='card-details col-12 col-lg-8'>
                                    {this.renderBattery()}
                                    {this.renderLastSeen()}
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        );
    }
}

class BeaconBattery extends React.PureComponent {

    getClassNameForbatteryLevel = () => {
        const batteryLevel = this.props.value;

        if (batteryLevel === 100) {
            return 'battery-full';
        } else if (batteryLevel >= 75) {
            return 'battery-6-bar';
        } else if (batteryLevel >= 30) {
            return 'battery-4-bar';
        } else if (batteryLevel < 30 && batteryLevel > 0) {
                return 'battery-2-bar';
        } else {
            return 'battery-0-bar';
        }
    }

    getIconForbatteryLevel = () => {
        return this.getClassNameForbatteryLevel().replace(/\-/g, '_');
    }

    render() {
        return (
            <div title='Battery Level' className={this.getClassNameForbatteryLevel() + " beacon-battery card-detail"}>
                <i className='material-icons'>{this.getIconForbatteryLevel()}</i>
                <span>{this.props.value}%</span>
            </div>
        );
    }
}