import moment from "moment";
import apiRequest, {checkAPIJWT, clearLocalAPIJWT} from "../../api/rcApi";

const BASE_ENDPOINT = '/fleets/{account}/beacons';
const QUERYSTRINGS = '?assetInfo=true&location=true&sensorInfo=true';

const CONVERSIONS = [
    function uuid(obj){
        if(obj.location){
            obj.beaconUuid = obj.location.substring(obj.location.lastIndexOf('/') + 1);
            delete obj.location;
        }
    },

    function lastSeen(obj){
        let ts = obj.ts ? obj.ts : 0;

        if(obj.sensors){
            Object.keys(obj.sensors).forEach(sensorType => {
                if(obj.sensors[sensorType].ts && obj.sensors[sensorType].ts > ts){
                    ts = obj.sensors[sensorType].ts;
                }
            });
        }
        
        if(ts && ts > 0){
            obj.lastSeen = moment(ts * 1000).format('ddd, MMM Do YYYY, hh:mm:ss A');
        }
    },

    function batteryLife(obj){
        if(obj.sensors){
            const batterySensor = obj.sensors.ASSET_TRACKER_STATUS_BATTERY_PERCENT;
            if(batterySensor){
                obj.batteryLife = {
                    value: batterySensor.value,
                    time: moment(batterySensor.ts).format('ddd, MMM Do YYYY, hh:mm:ss A')
                };
            }
        }
    },

    function snakeCaseToCamel(obj){
        const snakeCaseRegex = /_([a-z])/g;
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if(snakeCaseRegex.test(key)) {
                    const camelKey = key.replace(snakeCaseRegex, (g) => g[1].toUpperCase());
                    obj[camelKey] = obj[key];
                    delete obj[key];
                }
            }
        }
    }
];

const getEndpoint = (method, account, beacondId) => {
    let url = BASE_ENDPOINT.replace('{account}', account.accountExternalId);

    if(beacondId){
        url += `/${beacondId}`;
    }

    if(method === 'GET'){
        url += QUERYSTRINGS;
    }

    return url;
}

const getToken = async (stage) => {
    return await checkAPIJWT(stage);
}

const handleError = (error) => {
    return Promise.reject(error);
}

const convertToReadable = (list) => {
    function convertor(obj){
        CONVERSIONS.forEach((conversion) => {
            conversion(obj);
        });

        return obj;
    }

    // Don't proceed if list is undefined
    if(!list)
        return list;

    if(!Array.isArray(list)){
        return convertor(list);
    }

    list.forEach((obj) => {
        convertor(obj);
    });

    return list;
}

export default {
    selectors: {
        getBeacons: async (stage, account, limit, offset) => {
            const endpoint = getEndpoint("GET", account);
            
            const token = await getToken(stage);
            
            const response = await apiRequest(stage, endpoint, token, 'GET');

            const list = response.results;

            return convertToReadable(list);
        }
    },

    actions: {
        deleteBeacon: async (stage, account, beacon) => {
            const endpoint = getEndpoint("DELETE", account, beacon.beaconUuid);
            
            const token = await getToken(stage);
            
            const response = await apiRequest(stage, endpoint, token, 'DELETE');

            const deletedBeacon = Object.assign({}, beacon, convertToReadable(response));

            return deletedBeacon;
        },

        addBeacon: async (stage, account, newBeacon) => {
            const endpoint = getEndpoint("POST", account);
            
            const token = await getToken(stage);
            
            const response = await apiRequest(stage, endpoint, token, 'POST', newBeacon);
            
            const addedBeacon = Object.assign({}, newBeacon, convertToReadable(response));

            return addedBeacon;
        },

        resetToken: async (stage) => {
            clearLocalAPIJWT(stage);
        }
    }
}